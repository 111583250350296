import { getCurrentInstance, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import {
  getListAgent,
  submitAddKuotaAgent
} from '@/domain/usecase'
import { useStore } from 'vuex'
import { cloneDeep } from 'lodash'
import moment from 'moment'

export default {
  name: 'ListGroupAgent',
  setup() {
    const route = useRoute()
    const store = useStore()
    const app = getCurrentInstance()
    const dataForm = ref(null) as any
    const refChangeKuota = ref()
    const { $toast, $strInd } = app!.appContext.config.globalProperties
    const data = ref([]) as any
    const profileAgent = store.getters['appActiveUser/getAgent']

    /** Get data from api */
    const getData = () => {
      store.dispatch('showLoading')
      getListAgent({
        custom: `(KotaId eq ${store.state.appActiveUser.agent.kotaId} or Kota/MsGroupKotaId ne null) and (Kota/MsGroupKotaId eq ${store.state.appActiveUser.agent.cityGroupId})`,
        expand: `&$expand=KurirTransAbsensiMsAgenIdInNavigations($filter=Clockin eq ${moment().format('YYYY-MM-DD')};$top=0;$count=true),KurirMsUsers($top=0;$count=true;$filter=IsDeleted eq false and IsVerified eq true and IsBlocked eq false)`,
        select: '&$select=Id, Nama, Kode, Kuota'
      }).then((response: any) => {
        if (!response.error) {
          // eslint-disable-next-line array-callback-return
          data.value = response.result.map((x: any) => {
            x.CanChange = false
            x.Kuota = x.Kuota === null ? 0 : x.Kuota
            return x
          })
        } else {
          $toast.add({
            severity: 'error', detail: $strInd.toast.errorAllData, group: 'bc', life: 1500
          })
        }
        store.dispatch('hideLoading')
      })
    }

    /** Ubah attribute CanChange menjadi true untuk melakukan edit value */
    const tempDataForm = ref(null) as any
    const changeCanChange = (evt: any, val: any, index: any) => {
      refChangeKuota.value.toggle(evt)
      dataForm.value = val
      tempDataForm.value = cloneDeep(val)
      tempDataForm.value.Index = index
    }

    /** Proses untuk kirim ke api untuk mengganti kuota agent */
    const submitKuotaAgent = async () => {
      store.dispatch('showLoading')
      const dataSend = {
        kuota: dataForm.value.Kuota
      }
      const response = await submitAddKuotaAgent(dataForm.value.Id, dataSend)
      if (!response.error) {
        $toast.add({
          severity: 'success', detail: response.result.detail ?? response.result.Detail, group: 'bc', life: 1500
        })
      } else {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 1500
        })
        data.value[tempDataForm.value.Index].Kuota = tempDataForm.value.Kuota
      }
      store.dispatch('hideLoading')
    }

    /** Submit */
    const submit = () => {
      submitKuotaAgent()
    }
    onMounted(() => {
      getData()
    })
    return {
      route,
      data,
      store,
      changeCanChange,
      refChangeKuota,
      dataForm,
      submit
    }
  }
}
