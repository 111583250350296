import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableListTrackingCourier = _resolveComponent("TableListTrackingCourier")
  const _component_ListGroupAgent = _resolveComponent("ListGroupAgent")

  return (_openBlock(), _createBlock("section", null, [
    _createVNode(_component_TableListTrackingCourier, { class: "mb-5" }),
    _createVNode(_component_ListGroupAgent)
  ]))
}