import { getCurrentInstance, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import {
  courierLogAbsensiUseCase
} from '@/domain/usecase'
import { useStore } from 'vuex'
import moment from 'moment'

export default {
  name: 'TableListTrackingCourier',
  setup() {
    const route = useRoute()
    const store = useStore()
    const app = getCurrentInstance()
    const dataForm = ref(null) as any
    const refChangeKuota = ref()
    const { $confirm, $toast, $strInd } = app!.appContext.config.globalProperties
    const data = ref([]) as any

    /** Get data from api */
    const getData = () => {
      store.dispatch('showLoading')
      courierLogAbsensiUseCase.getAll({
        custom: `ClockIn eq ${moment().format('YYYY-MM-DD')} and MsAgenIdIn eq ${store.getters['appActiveUser/getAgent']?.id}`,
        select: '&$select=Id,BeratAssign,BeratDone,BeratKembali,ClockIn,ClockOut,KurirMsUserId,TotalAssign,TotalDone,TotalKembali,TotalBelumScan,BeratBelumScan',
        expand: '&$expand=KurirMsUser($select=Username,Fullname)'
      }).then((response: any) => {
        if (!response.error) {
          console.log('response =>', response)
          data.value = response.result
        } else {
          $toast.add({
            severity: 'error', detail: response.message, group: 'bc', life: 1500
          })
        }
        store.dispatch('hideLoading')
      })
    }

    onMounted(() => {
      getData()
    })
    return {
      route,
      data,
      store,
      refChangeKuota,
      dataForm,
      moment
    }
  }
}
